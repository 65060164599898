import { IconProps } from "./types";

export default (props: IconProps): JSX.Element => {
	const { width = 16, height = 16 } = props;
	return (
		<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="45" cy="45" r="42" stroke="var(--color-flamingo)" strokeWidth="6" />
			<path d="M38.0671 25.492C37.5634 25.1827 36.9861 25.0132 36.3948 25.0007C35.8036 24.9883 35.2197 25.1335 34.7034 25.4213C34.1871 25.7092 33.757 26.1292 33.4576 26.6382C33.1582 27.1471 33.0002 27.7266 33 28.3168V60.6832C33.0002 61.2734 33.1582 61.8529 33.4576 62.3618C33.757 62.8708 34.1871 63.2908 34.7034 63.5787C35.2197 63.8665 35.8036 64.0117 36.3948 63.9993C36.9861 63.9868 37.5634 63.8173 38.0671 63.508L64.4176 47.3248C64.9013 47.0278 65.3006 46.6121 65.5776 46.1175C65.8546 45.6228 66 45.0656 66 44.4989C66 43.9322 65.8546 43.375 65.5776 42.8803C65.3006 42.3856 64.9013 41.97 64.4176 41.673L38.0671 25.492Z" fill="var(--color-flamingo)" />
		</svg>
	)
}
