import { IconProps } from "./types";
import { css } from "@emotion/react";
export default (props: IconProps): JSX.Element => {
	const { width = 60, height = 60 } = props;
	return (
		<svg css={css`transform: translate(1px, 2px);`} width={width} height={height} viewBox={`0 0 512 512`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill="var(--color-flamingo)" d="M501.64,307.63a29.67,29.67,0,0,0-19.93-28.24l-2.63-.93,1.2-2.52a30,30,0,0,0-31.78-42.48V46.57a5.15,5.15,0,0,0-5.15-5.15H15.52a5.16,5.16,0,0,0-5.16,5.15v312a5.15,5.15,0,0,0,5.16,5.15H335.73c.21,0,.43,0,.64,0a30.21,30.21,0,0,0,6.55,9.72,29.58,29.58,0,0,0,30.76,7.2v84.87a5.15,5.15,0,0,0,9.07,3.34l25.91-30.39,25.91,30.39a5.14,5.14,0,0,0,3.92,1.81,5.27,5.27,0,0,0,1.78-.32,5.14,5.14,0,0,0,3.37-4.83V380.56a30,30,0,0,0,36.64-41.25l-1.2-2.52,2.63-.93A29.66,29.66,0,0,0,501.64,307.63Zm-481,45.75V51.73H438.2V237l-.37.18-.93-2.63a29.68,29.68,0,0,0-28.23-19.93h0a29.65,29.65,0,0,0-28.23,19.93l-.94,2.63L377,236a30,30,0,0,0-39.93,39.93l1.2,2.52-2.63.93a30,30,0,0,0,0,56.47l2.63.93-1.2,2.52a30.13,30.13,0,0,0-2.93,14.07ZM412.58,427.1a5.15,5.15,0,0,0-7.84,0L384,451.44V387.66a30,30,0,0,0,49.35,0v63.78Zm65.68-101-8,2.85a5.16,5.16,0,0,0-2.93,7.06l3.65,7.68a19.65,19.65,0,0,1-26.19,26.19l-7.68-3.64a5.14,5.14,0,0,0-7.06,2.92l-2.85,8a19.67,19.67,0,0,1-37.06,0l-2.84-8a5.14,5.14,0,0,0-4.85-3.42,5.07,5.07,0,0,0-2.22.5l-7.67,3.64a19.65,19.65,0,0,1-26.19-26.19l3.64-7.68a5.14,5.14,0,0,0-2.92-7.06l-8-2.85a19.66,19.66,0,0,1,0-37l8-2.85a5.14,5.14,0,0,0,2.92-7.06l-3.64-7.68a19.65,19.65,0,0,1,26.19-26.19l7.67,3.64a5.15,5.15,0,0,0,7.07-2.92l2.84-8a19.67,19.67,0,0,1,37.06,0l2.85,8A5.14,5.14,0,0,0,437.1,249l7.68-3.64A19.66,19.66,0,0,1,471,271.51l-3.65,7.68a5.16,5.16,0,0,0,2.93,7.06l8,2.85a19.66,19.66,0,0,1,0,37Z" />
			<path fill="var(--color-flamingo)" d="M408.66,252.8a54.83,54.83,0,1,0,54.83,54.83A54.88,54.88,0,0,0,408.66,252.8Zm0,99.35a44.53,44.53,0,1,1,44.53-44.52A44.57,44.57,0,0,1,408.66,352.15Z" />
			<path fill="var(--color-flamingo)" d="M178.46,102.7a5.14,5.14,0,0,0-5.15-5.15H62.53a5.15,5.15,0,1,0,0,10.3H173.31A5.14,5.14,0,0,0,178.46,102.7Z" />
			<path fill="var(--color-flamingo)" d="M109.49,164.65a5.14,5.14,0,0,0,5.15,5.15H344.23a5.15,5.15,0,0,0,0-10.3H114.64A5.14,5.14,0,0,0,109.49,164.65Z" />
			<path fill="var(--color-flamingo)" d="M317.31,211.3a5.14,5.14,0,0,0-5.15-5.15H114.64a5.15,5.15,0,0,0,0,10.3H312.16A5.14,5.14,0,0,0,317.31,211.3Z" />
			<path fill="var(--color-flamingo)" d="M280.09,252.79H114.64a5.16,5.16,0,0,0,0,10.31H280.09a5.16,5.16,0,0,0,0-10.31Z" />
		</svg>
	)
}
